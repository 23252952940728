import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { t } from "i18next";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { DATE_FORMAT } from "src/constants";
import { IClient } from "src/types/IClient";
import { IOCRDoc } from "src/types/IOCRDoc";
import { IReceiptQueue } from "src/types/IReceiptQueue";
import { axiosInstance } from "src/utils";
import { snackActions } from "src/utils/SnackbarUtils";

interface ReceiptQueueState {
  clients: IClient[];
  receiptQueues: IReceiptQueue[];
  currentReceiptQueue: IReceiptQueue;
  ocrDocs: IOCRDoc[];
  totals: {
    workingNow: number;
    inQueue: number;
    receievedToday: number;
    receivedEarlier: number;
  };
  isLoading: {
    receiptQueues: boolean;
    receiptQueue: boolean;
  };
}

interface ReceiptQueueContextValue {
  state?: ReceiptQueueState | null;
  dispatch?: (value) => void;
  getReceiptQueues?: () => Promise<void>;
  getReceiptQueue?: (id: number) => Promise<void | AxiosResponse<any, any>>;
  setReceiptQueue?: (receiptQueue: IReceiptQueue) => void;
  saveReceiptQueue?: (receiptQueue: IReceiptQueue) => Promise<void>;
  updateReceiptQueue?: (
    receiptQueue: IReceiptQueue,
    showSnackbar?: boolean
  ) => Promise<void | AxiosResponse<any, any>>;
  saveOCRDoc?: (ocrDoc: IOCRDoc) => void;
  deleteReceiptQueue?: (id: number) => Promise<void>;
}

const ReceiptQueueContext = createContext<ReceiptQueueContextValue>({});
const useReceiptQueue = (): ReceiptQueueContextValue =>
  useContext(ReceiptQueueContext);

const reducer = (state: ReceiptQueueState, action): ReceiptQueueState => {
  let newState = state;

  switch (action.type) {
    case "IS_LOADING": {
      const { parameter, status } = action.payload;
      newState = {
        ...state,
        isLoading: { ...state.isLoading, [parameter]: status },
      };
      break;
    }

    case "SET_RECEIPT_QUEUES": {
      const { receiptQueues } = action.payload;
      newState = {
        ...state,
        receiptQueues,
      };
      break;
    }

    case "SET_RECEIPT_QUEUE": {
      const { currentReceiptQueue } = action.payload;
      newState = {
        ...state,
        currentReceiptQueue,
        receiptQueues: state.receiptQueues?.map((receiptQueue) =>
          receiptQueue?.ReceiptQueueId === currentReceiptQueue?.ReceiptQueueId
            ? currentReceiptQueue
            : receiptQueue
        ),
      };
      break;
    }

    case "SET_OCR_DOCS": {
      const { ocrDocs } = action.payload;
      newState = {
        ...state,
        ocrDocs,
      };
      break;
    }

    case "UPDATED_TOTALS": {
      const { totals } = action.payload;
      newState = {
        ...state,
        totals,
      };
      break;
    }

    default: {
      return state;
    }
  }
  return newState;
};

interface ReceiptQueueProps {
  children: ReactNode;
}

const ReceiptQueueProvider: FC<ReceiptQueueProps> = ({
  children,
}: ReceiptQueueProps) => {
  const initialState: ReceiptQueueState = {
    clients: [],
    receiptQueues: [],
    currentReceiptQueue: null,
    ocrDocs: [],
    totals: {
      workingNow: 0,
      inQueue: 0,
      receievedToday: 0,
      receivedEarlier: 0,
    },
    isLoading: {
      receiptQueues: true,
      receiptQueue: true,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Update Totals
    dispatch({
      type: "UPDATED_TOTALS",
      payload: {
        totals: {
          workingNow: state.receiptQueues?.filter((r) => r.InProgress).length,
          inQueue: state.receiptQueues?.length,
          receievedToday: state.receiptQueues?.filter((r) =>
            dayjs(r.ReceivedDate).isSame(new Date(), "day")
          ).length,
          receivedEarlier: state.receiptQueues?.filter((r) =>
            dayjs(r.ReceivedDate).isBefore(new Date(), "day")
          ).length,
        },
      },
    });

    // update Note column
  }, [state.receiptQueues]);

  const getReceiptQueues = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "receiptQueues", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/ReceiptQueues`)
      .then((response) => {
        let receiptQueues: IReceiptQueue[] = response.data.ResponseData;

        // add dyanmic note column
        receiptQueues = receiptQueues?.map(generateReceiptQueueNote);

        dispatch({
          type: "SET_RECEIPT_QUEUES",
          payload: {
            receiptQueues,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_RECEIPT_QUEUES",
          payload: {
            receiptQueues: [],
          },
        });
        console.error(err);
        snackActions.error(
          err.status === 404 ? t("queue:error:noReceipts") : err.statusText
        );
      })
      .finally(() => {
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "receiptQueues", status: false },
        });
      });
  }, []);

  const setReceiptQueue = (receiptQueue) => {
    dispatch({
      type: "SET_RECEIPT_QUEUE",
      payload: {
        currentReceiptQueue: receiptQueue,
      },
    });
  };

  const getReceiptQueue = useCallback((receiptQueueId: number) => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "receiptQueue", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/ReceiptQueues/${receiptQueueId}`)
      .then((response) => {
        let receiptQueue: IReceiptQueue = response.data.ResponseData;
        if (receiptQueue) {
          dispatch({
            type: "SET_RECEIPT_QUEUE",
            payload: {
              currentReceiptQueue: generateReceiptQueueNote({
                ...receiptQueue,
                ReceivedDate: dayjs(receiptQueue.ReceivedDate).format(
                  DATE_FORMAT
                ),
                EarliestExpDate: receiptQueue.EarliestExpDate
                  ? dayjs(receiptQueue.EarliestExpDate).format(DATE_FORMAT)
                  : null,
              }),
            },
          });
        }
        return response;
      })
      .catch((err) => {
        console.error(err);
        snackActions.error(JSON.stringify(err));
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "receiptQueue", status: false },
        });
      });
  }, []);

  const saveReceiptQueue = (receiptQueue: IReceiptQueue) => {
    return axiosInstance
      .post(`/AutomationHero/SaveReceiptQueue`, receiptQueue)
      .then(() => {
        snackActions.success("Successfully saved receipt queue");
      });
  };

  const deleteReceiptQueue = (receiptQueueId: number) => {
    return axiosInstance
      .delete(`/AutomationHero/DeleteQueue/${receiptQueueId}`)
      .then(() => {
        snackActions.success("Successfully deleted receipt queue");
        window.location.reload();
      });
  };
  const updateReceiptQueue = (
    receiptQueue: IReceiptQueue,
    showSnackbar = true
  ) => {
    return axiosInstance
      .put(
        `/AutomationHero/UpdateReceiptQueue/${receiptQueue.ReceiptQueueId}`,
        receiptQueue
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        snackActions.error(err.data.Message);
        throw err;
      });
  };

  const saveOCRDoc = (ocrDoc: IOCRDoc) => {
    return axiosInstance
      .put(`/AutomationHero/SaveOCRDoc`, ocrDoc)
      .then(() => {
        snackActions.success("Successfully saved OCR doc");
      })
      .catch((err) => {
        console.error(err);
        snackActions.error(JSON.stringify(err));
      });
  };

  const value = useMemo(
    () => ({
      state,
      dispatch,
      getReceiptQueues,
      getReceiptQueue,
      setReceiptQueue,
      saveReceiptQueue,
      updateReceiptQueue,
      saveOCRDoc,
      deleteReceiptQueue,
    }),
    [state, getReceiptQueues, getReceiptQueue]
  );

  return (
    <ReceiptQueueContext.Provider value={value}>
      {children}
    </ReceiptQueueContext.Provider>
  );
};

// helpers

const generateReceiptQueueNote = (
  receiptQueue: IReceiptQueue
): IReceiptQueue => {
  let output = [];

  if (receiptQueue.EtrSubmission) output.push(t("queue:table:row:eCOC"));

  if (receiptQueue.Hold) output.push(t("queue:table:row:hold"));

  if (receiptQueue.PreApproved) output.push(t("queue:table:row:preApp"));

  if (receiptQueue.PrelogNum) output.push(t("queue:table:row:prelog"));

  if (receiptQueue.SubContract) output.push(t("queue:table:row:sub"));

  receiptQueue.Note = output.join(", ");

  return receiptQueue;
};

export { ReceiptQueueProvider, useReceiptQueue };
